<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="{ name: 'PropertyFinancial' }"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Financiële parameters
    </h2>

    <div class="tw-flex tw-flex-col xl:tw-flex-row tw-gap-8">
      <div class="tw-flex-shrink-0">
        <FormulateForm
          #default="{ isLoading }"
          v-model="values"
          name="calculator"
          invalid-message="Gelieve de verplichte velden correct in te vullen."
          class="tw-px-4 tw-py-2 tw-w-full xl:tw-max-w-lg tw-rounded-md tw-shadow-lg tw-border-0.5 tw-bg-success tw-bg-opacity-10"
          @submit="submit"
        >
          <!-- Jan Tanghe: Calculation are done with max 2 decimals -->
          <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
            <FormulateInput
              id="downpayment"
              type="number"
              name="downpayment"
              placeholder="Eigen middelen"
              validation="bail|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
              @input="checkIfDownpaymentEqualsTotalPurchasePrice"
            >
              <template #label>
                <label for="downpayment" class="formulate-label">
                  Eigen middelen
                  <button type="button" @click="showInfoModal('Eigen middelen', 'downpayment')">
                    <i class="fas fa-info-circle" />
                  </button>
                  <button
                    type=“button”
                    class="tw-ml-2"
                    @click.prevent="showChart"
                   >
                    <i class="fa fa-chart-line" />
                  </button>
                </label>
              </template>
              <div slot="suffix" class="input-unit">
                <i class="fas fa-euro-sign" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="rental_income_per_month"
              label="Huurinkomsten per maand"
              placeholder="Huurinkomsten per maand"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
              @input="recalculateMonthlyCosts"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-euro-sign" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="interest_rate"
              label="Rentevoet"
              placeholder="Rentevoet"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-percent" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="monthly_costs"
              :label="`Maandelijkse kosten (${percentage(values.monthly_costs_percentage)})`"
              placeholder="Maandelijkse kosten"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :disabled="true"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            >
              <template #label>
                <label for="downpayment" class="formulate-label">
                  Maandelijkse kosten
                  <button type="button" @click="showInfoModal('Maandelijkse kosten', 'monthly_costs')">
                    <i class="fas fa-info-circle" />
                  </button>
                </label>
              </template>
              <div slot="suffix" class="input-unit">
                <i class="fas fa-euro-sign" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="repayment_period_in_years"
              label="Looptijd van de lening"
              placeholder="Looptijd van de lening"
              validation="bail|required|number|min:0"
              step="1"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
              :disabled="repaymentPeriodInYearsDisabled"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-calendar-alt" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="resale_period_in_years"
              label="Verkoop na ... jaren"
              placeholder="Verkoop na ... jaren"
              validation="bail|required|number|min:0"
              step="1"
              min="0"
              max="25"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-calendar-alt" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="rental_index"
              label="Huurindex"
              placeholder="Huurindex"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            >
              <template #label>
                <label for="rental_index" class="formulate-label">
                  Huurindex
                  <button type="button" @click="showInfoModal('Huurindex', 'rental_index')">
                    <i class="fas fa-info-circle" />
                  </button>
                </label>
              </template>
              <div slot="suffix" class="input-unit">
                <i class="fas fa-percent" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="added_value"
              label="Meerwaarde"
              placeholder="Meerwaarde"
              validation="bail|required|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            >
              <template #label>
                <label for="add_value" class="formulate-label">
                  Meerwaarde
                  <button type="button" @click="showInfoModal('Meerwaarde', 'added_value')">
                    <i class="fas fa-info-circle" />
                  </button>
                </label>
              </template>
              <div slot="suffix" class="input-unit">
                <i class="fas fa-percent" />
              </div>
            </FormulateInput>
            <FormulateInput
              type="number"
              name="rental_management_percent"
              label="Rentmeesterschap"
              placeholder="Rentmeesterschap"
              validation="bail|number|min:0"
              step=".01"
              min="0"
              lang="nl"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            >
              <div slot="suffix" class="input-unit">
                <i class="fas fa-percent" />
              </div>
            </FormulateInput>
            <FormulateInput
              v-show="hasBothBuildAndRenovationYear"
              name="use_renovation_year_investment_calculation"
              type="select"
              label="Bouwjaar/renovatiejaar"
              :options="yearDropdownValues"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            />
            <FormulateInput
              v-show="!hasBothBuildAndRenovationYear"
              name="year"
              type="number"
              disabled="true"
              :label="yearLabel"
              :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
              :element-class="['tw-flex tw-items-center']"
              outer-class="tw-my-0"
            />
          </div>
          <FormulateInput
            v-if="values.show_vat_recovery"
            name="vat_recovery"
            type="checkbox"
            label="Btw-recuperatie"
            outer-class="tw-my-0"
          />
          <FormulateErrors />
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full']"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-calculator'
              ]"
            />
            Berekenen
          </FormulateInput>

          <div class="tw-flex tw-flex-row tw-justify-between tw-flex-wrap tw-items-center tw-text-base">
            <span class="formulate-label">
              Te ontlenen bedrag
              <button type="button" @click="showInfoModal('Te ontlenen bedrag', 'loan_amount')">
                <i class="fas fa-info-circle" />
              </button>
            </span>
            <span>{{ currency(values.loan_amount) }}</span>
          </div>
          <div class="tw-flex tw-flex-row tw-justify-between tw-flex-wrap tw-items-center tw-text-base">
            <span class="formulate-label">
              Afbetaling per maand
              <button type="button" @click="showInfoModal('Afbetaling per maand', 'monthly_installment')">
                <i class="fas fa-info-circle" />
              </button>
            </span>
            <span>{{ currency(values.monthly_installment) }}</span>
          </div>
        </FormulateForm>
        <FormulateForm
          #default="{ isLoading }"
          v-if="property"
          v-model="reportValues"
          invalid-message="Gelieve de verplichte velden correct in te vullen."
          class="tw-mt-8 tw-px-4 tw-py-2 tw-w-full xl:tw-max-w-lg tw-rounded-md tw-shadow-lg tw-border-0.5 tw-bg-success tw-bg-opacity-10"
          @submit="generateReport"
        >
          <FormulateInput
            type="autocomplete"
            auto-complete-type="contact"
            name="contact"
            label="Contact (optioneel)"
            placeholder="Selecteer optioneel een contact"
            @input="processContactSelection"
          >
            <template #label>
              <label for="rental_index" class="formulate-label">
                Contact (optioneel)
                <button type="button" @click="showInfoModal('Contact', '', selectContactInfoText)">
                  <i class="fas fa-info-circle" />
                </button>
              </label>
            </template>
          </FormulateInput>
          <FormulateInput
            type="select"
            name="language"
            label="Taal rapport"
            placeholder="Selecteer een taal"
            :options="USER_LANG_OPTIONS"
            validation="required"
          />
          <FormulateErrors />
          <div class="tw-flex tw-flex-row tw-gap-2">
            <FormulateInput
              type="submit"
              title="Definitief rapport"
              :disabled="isLoading"
              :input-class="['tw-bg-primary tw-w-full tw-text-sm']"
              outer-class="tw-m-0 tw-flex-grow"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  isLoading ? 'fa fa-spinner-third fa-spin' : 'fa-print'
                ]"
              />
              Rapport ({{ property.status === 1 ? 'gratis' : 'betalend' }})
            </FormulateInput>
          </div>
        </FormulateForm>

        <FormulateForm v-if="reportGenerated" class="tw-mt-8 tw-px-4 tw-py-2 tw-w-full xl:tw-max-w-lg tw-rounded-md tw-shadow-lg tw-border-0.5 tw-bg-success tw-bg-opacity-10">
          <FormulateInput
            v-if="!contactSelected"
            type="submit"
            :input-class="['tw-w-full tw-text-sm']"
            outer-class="tw-m-0 tw-flex-grow"
            @click="storeInvestmentReportAsPropertyFile"
            :disabled="storedAsPropertyFile"
          >
            <span v-if="!storedAsPropertyFile && !storingAsPropertyFile">
              <i
                class='fa fa-save tw-mr-2'
              />
              Opslaan op pand
            </span>
            <span v-else-if="storingAsPropertyFile">
              <i
                class='fas fa-spin fa-spinner-third tw-mr-2'
              />
              Bezig met opslaan ...
            </span>
            <span v-else>
              <i
                class='fa fa-check tw-mr-2'
              />
              Investeringsrapport opgeslagen op pand
            </span>
          </FormulateInput>
          <div v-if="contactSelected" class="tw-mb-2">
            <ul>
              <li>
                <i class='fa fa-check tw-mr-2 tw-text-green-500' />
                  <span>Lead aangemaakt</span>
                  <router-link
                    v-if="savedInvestmentReportForLead"
                    :to="{ name: 'LeadDetails', params: { id: savedInvestmentReportForLead.match_id } }"
                    target="_blank"
                    class="tw-underline">
                     (klik)
                  </router-link>
                </li>
              <li><i class='fa fa-check tw-mr-2 tw-text-green-500' />Rapport opgeslagen als bestand op lead</li>
              <li v-if="!contactHasEmailAddress"><i class="fa fa-close tw-mr-2 tw-text-red-500" /> Contact heeft geen e-mailadres</li>
              <li v-else><i :class="['fa', sentViaMailClient ? 'fa-check tw-mr-2 tw-text-green-500' : 'fa-circle tw-mr-2']" />Rapport verstuurd via mail</li>
            </ul>
          </div>
          <FormulateInput
            v-if="contactSelected"
            type="submit"
            :input-class="['tw-w-full tw-text-sm']"
            outer-class="tw-m-0 tw-flex-grow"
            @click="openMailClient"
            :disabled="sentViaMailClient || !contactHasEmailAddress"
          >
            <i
              class='fa fa-send tw-mr-2'
            />
            Versturen via mailclient
          </FormulateInput>
        </FormulateForm>
      </div>

      <div class="tw-flex-grow tw-min-w-0">
        <div class="tw-p-5 tw-bg-success tw-shadow-lg tw-w-full">
          <h2 class="tw-m-0 tw-text-2xl tw-text-white">Samenvatting</h2>
          <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-w-full tw-text-white">
            <div class="tw-px-4 tw-py-2 tw-bg-white tw-bg-opacity-10 tw-flex-grow">
              <h3 class="tw-font-thin">Prijs</h3>
              <span class="tw-text-xl">{{ currency(summary.total_purchase_price) }}</span>
            </div>
            <div class="tw-px-4 tw-py-2 tw-bg-white tw-bg-opacity-10 tw-flex-grow">
              <h3 class="tw-font-thin">Eigen middelen</h3>
              <span class="tw-text-xl">{{ currency(summary.downpayment) }}</span>
            </div>
            <div class="tw-px-4 tw-py-2 tw-bg-white tw-bg-opacity-10 tw-flex-grow">
              <h3 class="tw-font-thin">
                Netto huurrendement
                <button type="button" @click="showInfoModal('Netto huurrendement', 'return_on_investment_net')">
                  <i class="fas fa-info-circle" />
                </button>
              </h3>
              <span class="tw-text-xl">{{ percentage(summary.return_on_investment_net) }}</span>
            </div>
            <div class="tw-px-4 tw-py-2 tw-bg-white tw-bg-opacity-10 tw-flex-grow">
              <h3 class="tw-font-thin">
                Bruto huurrendement
                <button type="button" @click="showInfoModal('Bruto huurrendement', 'return_on_investment_gross')">
                  <i class="fas fa-info-circle" />
                </button>
              </h3>
              <span class="tw-text-xl">{{ percentage(summary.return_on_investment_gross) }}</span>
            </div>
            <div class="tw-px-4 tw-py-2 tw-bg-white tw-bg-opacity-10 tw-flex-grow">
              <h3 class="tw-font-thin">
                Jaarlijks rendement
                <button type="button" @click="showInfoModal('Jaarlijks rendement', 'annual_return')">
                  <i class="fas fa-info-circle" />
                </button>
              </h3>
              <span class="tw-text-xl">{{ percentage(summary.annual_return) }}</span>
            </div>
          </div>
        </div>

        <div class="tw-my-4 tw-overflow-auto">
          <table class="tw-w-full tw-table-auto tw-border-0.5 tw-border-collapse">
            <thead>
              <tr>
                <th scope="col" class="tw-font-semibold">Jaar</th>
                <th scope="col" class="tw-font-semibold">
                  Huurinkomsten
                  <button type="button" @click="showInfoModal('Huurinkomsten', 'rental_income')">
                    <i class="fas fa-info-circle" />
                  </button>
                </th>
                <th scope="col" class="tw-font-semibold">
                  Kosten
                  <button type="button" @click="showInfoModal('Kosten', 'costs')">
                    <i class="fas fa-info-circle" />
                  </button>
                </th>
                <th scope="col" class="tw-font-semibold">
                  Huuropbrengsten
                  <button type="button" @click="showInfoModal('Huuropbrengsten', 'net_rental_income')">
                    <i class="fas fa-info-circle" />
                  </button>
                </th>
                <th scope="col" class="tw-font-semibold">Afbetaling per jaar</th>
                <th scope="col" class="tw-font-semibold">
                  Cashflow
                  <button type="button" @click="showInfoModal('Cashflow', 'cashflow')">
                    <i class="fas fa-info-circle" />
                  </button>
                </th>
                <th scope="col" class="tw-font-semibold">
                  Waardebepaling
                  <button type="button" @click="showInfoModal('Waardebepaling', 'valuation')">
                    <i class="fas fa-info-circle" />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in investmentReportRows" :key="row.year">
                <td>{{ row.year }}</td>
                <td>{{ currency(row.rental_income) }}</td>
                <td>{{ currency(row.costs) }}</td>
                <td>{{ currency(row.net_rental_income) }}</td>
                <td>{{ currency(row.payment_per_year) }}</td>
                <td>{{ currency(row.cashflow) }}</td>
                <td>{{ currency(row.valuation) }}</td>
              </tr>
              <tr class="tw-font-semibold">
                <td>{{ investmentReportLastRow.year ? 'Verkoop ' + investmentReportLastRow.year : '-' }}</td>
                <td>{{ currency(investmentReportLastRow.rental_income) }}</td>
                <td>{{ currency(investmentReportLastRow.costs) }}</td>
                <td>{{ currency(investmentReportLastRow.net_rental_income) }}</td>
                <td>{{ currency(investmentReportLastRow.payment_per_year) }}</td>
                <td>{{ currency(investmentReportLastRow.cashflow) }}</td>
                <td>{{ currency(investmentReportLastRow.valuation) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="tw-my-4 tw-overflow-auto">
          <table class="tw-w-full tw-table-auto tw-border-0.5 tw-border-collapse">
            <caption class="tw-italic">Meerwaarde {{ percentage(values.added_value) }}</caption>
            <thead>
              <tr>
                <th class="tw-font-semibold">Eigen middelen</th>
                <th class="tw-font-semibold">Som maandelijkse eigen inbreng</th>
                <th class="tw-font-semibold">Cash na verkoop</th>
                <th class="tw-font-semibold">Gecreëerde opbrengst</th>
                <th class="tw-font-semibold">Rendement op geïnvesteerd kapitaal</th>
                <th class="tw-font-semibold">Jaarlijks rendement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ currency(roi.downpayment) }}</td>
                <td>{{ currency(roi.total_payments) }}</td>
                <td>{{ currency(roi.cash_after_sale) }}</td>
                <td>{{ currency(roi.revenue_generated) }}</td>
                <td>{{ percentage(roi.return_on_capital) }}</td>
                <td>{{ percentage(roi.annual_return) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="tw-my-4 tw-overflow-auto">
          <table class="tw-w-full tw-table-auto tw-border-0.5 tw-border-collapse">
            <caption class="tw-italic">Meerwaarde 0%</caption>
            <thead>
              <tr>
                <th class="tw-font-semibold">Eigen middelen</th>
                <th class="tw-font-semibold">Som maandelijkse eigen inbreng</th>
                <th class="tw-font-semibold">Cash na verkoop</th>
                <th class="tw-font-semibold">Gecreëerde opbrengst</th>
                <th class="tw-font-semibold">Rendement op geïnvesteerd kapitaal</th>
                <th class="tw-font-semibold">Jaarlijks rendement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ currency(roi_no_added_value.downpayment) }}</td>
                <td>{{ currency(roi_no_added_value.total_payments) }}</td>
                <td>{{ currency(roi_no_added_value.cash_after_sale) }}</td>
                <td>{{ currency(roi_no_added_value.revenue_generated) }}</td>
                <td>{{ percentage(roi_no_added_value.return_on_capital) }}</td>
                <td>{{ percentage(roi_no_added_value.annual_return) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <BaseModal ref="info" :title="info.title">
      <span v-html="info.text" />
    </BaseModal>
    <iframe
      v-if="pdfUrl"
      id="pdf_frame"
      type="application/pdf"
      :src="pdfUrl"
      class="tw-my-8 tw-w-full tw-h-full tw-min-h-[1200px]"
    />
    <BaseModal ref="chartModal" title="Jaarlijks rendement in functie van aandeel eigen middelen" max-width="tw-max-w-5xl">
        <DownpaymentReturnsChart :chart-data="chartData"/>
        <div class="tw-flex tw-justify-center">
          <table class="tw-w-4/5 tw-mt-4 tw-table-fixed tw-border-0.5 tw-border-collapse">
            <thead>
              <tr>
                <td class="tw-font-semibold">Aandeel eigen middelen</td>
                <td class="tw-font-semibold">Eigen middelen</td>
                <td class="tw-font-semibold">Jaarlijks rendement</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="downpaymentReturn in values.downpayment_returns" :key="downpaymentReturn.share">
                <td>{{ percentage(downpaymentReturn.share) }}</td>
                <td>{{ currency(downpaymentReturn.downpayment) }}</td>
                <td>{{ percentage(downpaymentReturn.annual_return) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </BaseModal>
  </div>
</template>

<script>
import EventBus from '@/components/iam/bus'
import DownpaymentReturnsChart from '@/components/properties/DownpaymentReturnsChart.vue'

import { mapGetters } from 'vuex'
import { errorModal } from '@/modalMessages'
import { currency, percentage, poll, USER_LANG_OPTIONS } from '@/utils/helpers'

import { createLog } from '@/services/apiService'
import {
  getProperty,
  getInvestmentGridDefaultValues,
  calculateInvestmentGrid,
  calculateInvestmentReportData,
  storeInvestmentReportAsLeadFile,
  storeGutenborgFile,
  updatePropertyFinancial
} from '@/services/properties'
import {
  getTemplates,
  createDocument,
  createV2Document,
  pollGutenborg,
  pollV2Gutenborg
} from '@/services/gutenborgService'

export default {
  name: 'PropertyFinancialCalculator',
  components: { DownpaymentReturnsChart },
  constants: {
    USER_LANG_OPTIONS
  },
  data () {
    return {
      values: {},
      reportValues: { language: 'nl' },
      summary: {},
      roi: {},
      roi_no_added_value: {},
      investmentReportRows: {},
      investmentReportLastRow: {},
      labels: {},
      info: {},
      repaymentPeriodInYearsDisabled: false,
      initialUseRenovationYearInvestmentCalculation: '',
      // Report generation values
      pdfUrl: null,
      reportGenerated: false,
      storingAsPropertyFile: false,
      storedAsPropertyFile: false,
      savedInvestmentReportForLead: {},
      sentViaMailClient: false,
      chartData: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById']),

    property () {
      return this.getPropertyById(this.propertyId)
    },
    propertyId () {
      return this.$route.params.id
    },
    contactSelected () {
      return !!this.reportValues?.contact
    },
    contactHasEmailAddress () {
      return !!this.reportValues?.contact?.email
    },
    selectContactInfoText () {
      return 'Een contact kan optioneel worden geselecteerd. Na het genereren van een definitief rapport zal automatisch een lead worden aangemaakt en wordt het bestand opgeslagen op de lead.<br><br>Wanneer geen contact wordt geselecteerd, kan het bestand op het pand zelf worden opgeslagen voor later gebruik.'
    },
    hasBothBuildAndRenovationYear () {
      return this.values.build_year && this.values.renovation_year
    },
    yearLabel () {
      if (this.hasBothBuildAndRenovationYear) return ''
      if (this.values.build_year) return 'Bouwjaar'
      return 'Renovatiejaar'
    },
    yearDropdownValues () {
      return [
        {
          value: 'false', label: `Bouwjaar (${this.values.build_year})`
        },
        {
          value: 'true', label: `Renovatiejaar (${this.values.renovation_year})`
        }
      ]
    }
  },
  created () {
    this.fetchInvestmentGridDefaultValues(this.propertyId)
  },
  methods: {
    currency,
    percentage,
    goBack () {
      this.$router.push({ name: 'PropertyFinancial' })
    },
    showInfoModal (title, key = '', content = '') {
      if (!content) content = this.labels[key]?.text_nl
      this.info = { title, text: content }
      this.$refs.info.show()
    },
    async fetchInvestmentGridDefaultValues (propertyId) {
      const response = await getInvestmentGridDefaultValues(propertyId)
      const {
        labels,
        monthly_rent,
        show_vat_recovery,
        ...values
      } = response.data

      // To compare against HTML boolean option (string)
      const use_renovation_year_investment_calculation = response.data.use_renovation_year_investment_calculation.toString()
      this.values = {
        show_vat_recovery,
        vat_recovery: show_vat_recovery,
        rental_income_per_month: monthly_rent,
        use_renovation_year_investment_calculation,
        ...values
      }
      this.labels = labels
      this.initialUseRenovationYearInvestmentCalculation = use_renovation_year_investment_calculation
      if (!this.hasBothBuildAndRenovationYear) this.$set(this.values, 'year', values.build_year || values.renovation_year)
      this.setChartData()
      return response
    },
    showChart () {
      this.$refs.chartModal.show()
    },
    setChartData () {
      this.chartData = {
        labels: this.values.downpayment_returns.map(obj => currency(obj.downpayment)),
        datasets: [
          {
            label: 'Eigen middelen / rendement (%)',
            data: this.values.downpayment_returns.map(obj => obj.annual_return),
            fill: false
          }
        ]
      }
    },
    checkIfDownpaymentEqualsTotalPurchasePrice () {
      const totalPurchasePrice = parseFloat(this.values.total_purchase_price)
      const downpayment = parseFloat(this.values.downpayment)
      if (totalPurchasePrice === downpayment) {
        this.$set(this.values, 'repayment_period_in_years', 0)
        this.repaymentPeriodInYearsDisabled = true
      } else {
        this.repaymentPeriodInYearsDisabled = false
      }
    },
    processContactSelection () {
      this.setLanguage()
      this.resetStatusTrackingValues()
    },
    setLanguage () {
      if (this.reportValues.contact) {
        this.reportValues.language = this.reportValues.contact.language
      } else {
        this.reportValues.language = 'nl'
      }
    },
    resetStatusTrackingValues () {
      this.pdfUrl = null
      this.reportGenerated = false
      this.storingAsPropertyFile = false
      this.storedAsPropertyFile = false
      this.savedInvestmentReportForLead = {}
      this.sentViaMailClient = false
    },
    recalculateMonthlyCosts () {
      const calculatedValue = this.values.rental_income_per_month * (this.values.monthly_costs_percentage / 100)
      const roundedValue = Math.round(calculatedValue * 100) / 100
      this.$set(this.values, 'monthly_costs', roundedValue)
      return roundedValue
    },

    async submit (data) {
      try {
        const { use_renovation_year_investment_calculation } = data
        if (this.hasBothBuildAndRenovationYear && data.use_renovation_year_investment_calculation !== this.initialUseRenovationYearInvestmentCalculation) {
          await updatePropertyFinancial(this.propertyId, { use_renovation_year_investment_calculation })
          // Recalculate monthly costs based after retrieving (possible) new costs percentage rate
          this.initialUseRenovationYearInvestmentCalculation = use_renovation_year_investment_calculation
          const response = await getInvestmentGridDefaultValues(this.propertyId)
          this.$set(this.values, 'monthly_costs_percentage', response.data.monthly_costs_percentage)
          // Set newly calculated costs value in payload
          data.monthly_costs = this.recalculateMonthlyCosts()
        }

        const response = await calculateInvestmentGrid(this.propertyId, data)
        const {
          summary_data,
          roi_data,
          roi_data_no_added_value,
          investment_report_rows,
          investment_report_last_row,
          monthly_installment,
          loan_amount,
          downpayment_returns
        } = response.data

        this.summary = summary_data
        this.roi = roi_data
        this.roi_no_added_value = roi_data_no_added_value
        this.investmentReportRows = investment_report_rows
        this.investmentReportLastRow = investment_report_last_row

        this.$set(this.values, 'monthly_installment', monthly_installment)
        this.$set(this.values, 'loan_amount', loan_amount)
        this.$set(this.values, 'downpayment_returns', downpayment_returns)
        this.setChartData()

        this.$formulate.resetValidation('calculator')
        return response
      } catch (error) {
        console.error(error)
        if (!error.response?.data.detail) {
          error.response.data.detail = error?.response?.data?.code === 'NEGATIVE_RETURN'
            ? 'Ingevoerde gegevens leiden tot een negatief percentage'
            : 'Kan niet berekenen'
        }
        // ", probeer aub opnieuw." will get appended to the error detail. See apiErrorHandler in formulate.js
        this.$formulate.handle(error, 'calculator')
      }
    },
    async getFullReport (mergeData) {
      const documentPayload = {
        preview: false,
        data: { ...mergeData },
        lang1: this.reportValues.language
      }
      documentPayload.template_key = `investment_report_full_${this.reportValues.language}`
      const generate = await createV2Document(documentPayload)
      const pollResult = await poll(generate?.data?.job_id, pollV2Gutenborg, 1000)
      return pollResult
    },
    async getMinimalReport (mergeData) {
      const response = await getTemplates(8) // Type 8 returns the template we need
      const template_id = response?.data?.templates?.[0]?.id
      if (!template_id) throw new Error('No template found!')

      const merge_data = btoa(encodeURIComponent(JSON.stringify(mergeData)))
      const payload = {
        merge_data,
        template_id,
        config: {
          type: 'pdf',
          destination: 'temp',
          format: 'B64PDF',
          landscape: false
        }
      }
      const document = await createDocument(payload)
      const pollResult = await poll(document?.data?.uuid, pollGutenborg, 1000)
      return pollResult
    },
    async generateReport () {
      try {
        const payload = { ...this.reportValues, ...this.values }
        const response = await calculateInvestmentReportData(this.propertyId, payload)
        const result = this.property.status === 1
          ? await this.getMinimalReport(response.data)
          : await this.getFullReport(response.data)

        this.pdfUrl = result.url
        if (this.reportValues?.contact) {
          const payload = { contact_id: this.reportValues.contact.id, url: this.pdfUrl }
          const response = await storeInvestmentReportAsLeadFile(this.propertyId, payload)
          this.savedInvestmentReportForLead = response?.data
        }
        const logPayload = {
          action_id: 8, // Investment report action id
          content_object_id: this.propertyId,
          content_object_class: 'property'
        }
        await createLog(logPayload)
        this.reportGenerated = true
        return true
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het genereren van het rapport, gelieve nogmaals te proberen')
      }
    },

    async openMailClient () {
      try {
        const contacts = [{ type: 'candidate', contact: this.reportValues.contact }]
        const response = await getProperty(this.propertyId)
        const properties = [response.data]
        EventBus.$emit('mailclient-prefill-and-show', {
          contacts,
          template: {
            id: 239,
            language: this.reportValues.contact.language.toLowerCase()
          },
          attachments: [this.savedInvestmentReportForLead],
          context: { properties },
          callback: async () => {
            const payload = {
              action_id: 13, // Investment report mail action id
              content_object_id: this.propertyId,
              content_object_class: 'property',
              meta: { contact: this.reportValues.contact.id }
            }
            await createLog(payload)
            this.sentViaMailClient = true
          }
        })
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het openen van de mailclient.')
      }
    },
    async storeInvestmentReportAsPropertyFile () {
      try {
        this.storingAsPropertyFile = true
        const payload = {
          url: this.pdfUrl,
          type: 'investment_report'
        }
        const response = await storeGutenborgFile(this.propertyId, payload)
        this.storedAsPropertyFile = true
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij opslaan van het investeringsrapport op het pand.')
      } finally {
        this.storingAsPropertyFile = false
      }
    }
  }
}
</script>

<style scoped>
th, td {
  @apply tw-px-4 tw-py-2 tw-text-left tw-text-sm;
}
tr {
  @apply tw-border-b-0.5 tw-divide-x-0.5 even:tw-bg-success even:tw-bg-opacity-10;
}
</style>
