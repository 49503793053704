<script>
import { Line, mixins } from 'vue-chartjs'

export default {
  name: 'DownpaymentReturnsChart',
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData'],
  mounted () {
    const options = {
      responsive: true,
      maintainAspectRatio: false
    }
    this.renderChart(this.chartData, options)
  }
}
</script>
